<template>
  <el-select
    :size="size"
    v-model="value"
    :placeholder="placeholder"
    @change="handleChange"
    filterable
    clearable
  >
    <el-option v-for="item in options" :key="item.index" :label="item.jobName" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "MuPersonnel",
  props: ["code", "defaultValue", "size", "placeholder"],
  data() {
    return {
      options: [],
      value: ""
    };
  },
  watch: {
    defaultValue: {
      handler: function(val) {
        this.value = val;
      }
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("setMValue", value);
    },
    initDictData() {
      //根据字典Dict, 初始化字典数组
      let code = this.code;
      this.$axios
        .get("/api/app-jycy-job/getJobNameListByCompanyId")
        .then(res => {
          this.options = res.data.data;
        });
    }
  },
  created() {
    this.value = this.defaultValue;
    this.initDictData();
  }
};
</script>
