<template>
  <div>
    <div class="flex">
      <div class="info-person cursorP" v-if="items">
        <ul>
          <li class="out cursorP">
            <div class="out-main" @click="goRouter('/public/resume/', items.memberId, 2, items.id)">
              <div class="profile">
                <div class="outside">
                  <img :src="items.memberObject.imageurl ||''" alt class="err_avatar"/>
                </div>
              </div>

              <div class="info-text" v-if="items.memberObject">
                <div class="flex tex">
                  <div class="flex">
                    <h4 class="line_clamp1">
                      {{ items.memberObject.userName }}
                    </h4>

                    <!-- <span
                      class="nini"
                      v-if="items.jobOtherInfo"
                    >{{items.jobOtherInfo.jobInfoStatus==1?'招聘会':null}}</span>-->
                  </div>
                  <span>{{ items.memberObject.sex }}</span>
                  <!-- <p>远程面试</p> -->
                </div>

                <div class="out-1">
                  <p v-if="items.memberObject.age">
                    <span>{{ items.memberObject.age }}岁</span>
                  </p>
                  <p v-else>
                    <span>未填写年龄</span>
                  </p>
                  <p v-if="items.memberObject.edu">
                    <span>{{ items.memberObject.edu }}</span>
                  </p>
                  <p v-else>
                    <span>未填写学历</span>
                  </p>
                  <p v-if="items.memberObject.exp">
                    <span>{{ items.memberObject.exp }}</span>
                  </p>
                  <p v-else>
                    <span>未填写工作经验</span>
                  </p>
                </div>

                <div class="out-1" v-if="item.memberObject.disabledType.length > 0">
                  <p v-for="(n,i) in item.memberObject.disabledType.slice(0,1)" :key="i">
                      <span
                      >残疾类别：{{
                          n.disabledType + '/' + n.disabledLevel
                        }}</span
                      >
                  </p>
                </div>
                <div class="out-1" v-else>
                  <p>
                      <span>
                        残疾类别：暂无残疾类别
                      </span>
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li class="out">
            <div
                class="out-flex"
                v-if="
                !items.memberObject ||
                  items.memberObject.resumeExpect.length == 0
              "
            >
              <p class="salary">期望薪资：暂无</p>
              <p class="line_clamp1">
                应聘职位：暂无
              </p>
              <p class="line_clamp1">求职意向：暂无</p>
              <p>期望地点：暂无</p>
            </div>
            <div v-else>
              <div
                  class="out-flex"
                  v-for="item in items.memberObject.resumeExpect || []"
                  :key="item.index"
              >
                <p class="salary">期望薪资：{{ item.salary }}</p>
                <p class="line_clamp1" v-if="items.jobObject">
                  应聘职位：{{ items.jobObject.jobName }}
                </p>
                <p class="line_clamp1">求职意向：{{ item.jobNext }}</p>
                <p>期望地点：{{ item.cityid }}</p>
              </div>
            </div>
          </li>
          <li class="out">
            <div>
              <p class="post">投递时间：{{ items.createTime }}</p>
              <!-- <p class="post" v-if="items.memberObject.updateTime == null">
                简历更新时间：暂无
              </p>
              <p class="post" v-else>
                简历更新时间：{{ items.memberObject.updateTime }}
              </p> -->
              <p class="post" v-if="items.reservationTime">
                预约时间：{{ items.reservationTime }}
              </p>
              <div class="info-right" v-if="ims == 0">
                <p
                    class="look cursorP"
                    @click="goRouter('/public/resume/', items.memberId, 2, items.jobObject)"
                >
                  <a>看简历</a>
                </p>
                <p
                    class="look"
                    @click="invatie(items.memberId, items.jobObject,items.id)"
                    v-if="items.applyStatus < '4'"
                >
                  <a>邀面试</a>
                </p>
                <p class="look" v-if="items.applyStatus == '7'">
                  <a class="colorFF5">不合适</a>
                </p>
              </div>
              <div class="info-right" :style="{width:timeType?'245px':'210px'}" v-else>
                <p
                    class="look cursorP"
                    @click="
                    goRouter('/public/resume/', items.memberId, 2, item.id)
                  "
                >
                  <a>看简历</a>
                </p>
                <p
                    class="look"
                    @click="intention(items.memberId)"
                    v-if="items.applyStatus < '3' ||items.applyStatus == '7'"
                >
                  <a>有意向</a>
                </p>
                <p
                    class="look"
                    @click="invatie(items.memberId, items.jobObject,items.id)"
                    v-if="items.applyStatus < '4' ||items.applyStatus == '7'"
                    @guanbi="guanbi"
                >
                  <a>邀面试</a>
                </p>

                <p
                    class="look"
                    @click="noFit(items.memberId)"
                    v-if="items.applyStatus < '6'"
                >
                  <a>不合适</a>
                </p>
                <p class="look fits" v-show="items.applyStatus === '4'">
                  <a class="a1">待回复</a>
                </p>

                <p class="look fits" v-show="items.applyStatus === '5'">
                  <a class="a2">已接受</a>
                </p>

                <p class="look fits" v-show="items.applyStatus === '6'">
                  <a class="a3">被拒绝</a>
                </p>
                <p class="look fits" v-show="timeType">
                  <a :class="timeType == 2?'a1':'a3'">{{ timeType == 2 ? '待面试' : '已过期' }}</a>
                </p>
                <p
                    class="look fits"
                    @click="openJob(1,items)"
                    v-show="timeType"
                >
                  <a>面试信息</a>
                </p>
                <p
                    class="look fits"
                    @click="openJob(2,items)"
                    v-show="timeType == 1"
                >
                  <el-dropdown @command="handleCommand">
                    <a>{{ items.finalMsg ? items.finalMsg : '面试反馈' }} <i class="el-icon-arrow-down"></i></a>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="成功入职">成功入职</el-dropdown-item>
                      <el-dropdown-item command="暂未入职">暂未入职</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </p>
              </div>
            </div>
            <div class="buttons" v-if="city === '河南省'">
              <div
                  class="green_plain"
                  @click="openSubscribe(item.id)"
                  v-if="items.reservationStatus == -1"
              >
                预约视频面试
              </div>
              <div class="gray" v-if="items.reservationStatus == 0">
                等待同意面试
              </div>
              <div
                  class="green"
                  @click="openVideo(item.id)"
                  v-if="items.reservationStatus == 1"
              >
                开始视频面试
              </div>
              <div class="red" v-if="items.reservationStatus == 2">
                已拒绝视频面试
              </div>
              <div class="org" v-if="items.reservationStatus == 3">
                面试时间已过期
              </div>
            </div>
          </li>
        </ul>
        <div
            class="display-none"
            @click="collect(items.memberId, items.collectionType)"
        >
          <div class="star">
            <span
                :class="items.collectionType == 1 ? 'iscollect' : 'uncollect'"
            ></span>
            <span>{{ items.collectionType == 1 ? "已收藏" : "收藏" }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          title="面试邀请"
          width="40%"
          :visible.sync="dialogFormVisible"
          v-if="dialogFormVisible"
          class="bg"
      >
        <Invative @close="close()" :jobInfo="jobInfo" :time="time"></Invative>
      </el-dialog>
      <!--      预约面试时间-->
      <el-dialog
          title="预约面试时间"
          width="40%"
          :visible.sync="isHide"
          class="bg_video"
      >
        <el-form
            ref="form"
            :model="form"
            :rules="error"
            v-if="form"
            label-width="100px"
        >
          <el-form-item label="联系人：" prop="linkMan">
            <el-input
                placeholder="请输入联系人"
                v-model="form.linkMan"
                class="width90"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人方式：" prop="linkTel">
            <el-input
                placeholder="请输入手机号，方便对方联系"
                v-model="form.linkTel"
                class="width90"
            ></el-input>
          </el-form-item>
          <el-form-item label="预约时间：" prop="reservationTime">
            <el-date-picker
                v-model="form.reservationTime"
                type="datetime"
                class="width90"
                value-format="yyyy-MM-dd HH:mm:ss"
                default-time="09:00:00"
                placeholder="选择预约时间"
                popper-class="eagle-a11y-uncut"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="footer" slot="footer">
          <el-button type="primary" @click="reservation" :disabled="disabled"
          >确定
          </el-button
          >
          <el-button type="primary" plain @click="isHide = false"
          >取消
          </el-button
          >
        </div>
      </el-dialog>
      <!--      开始视频面试-->
      <video-invitation
          :hide="isShow"
          ref="video"
          @close="isShow = false"
      ></video-invitation>
    </div>
    <!--面试结果 -->
    <el-dialog
        title="面试结果"
        width="30%"
        :visible.sync="isShows.show"
        class="bg_video"
        :before-close="close"
    >
      <div class="el-dialog_content">
        <ul class="ul">
          <li>
            <span class="name">面试结果：</span>
            <span class="text color00">{{ items.applyStatus | getStatus(items.applyStatus) }}</span>
            <el-dropdown @command="handleCommand">
              <el-button type="primary">{{ items.finalMsg ? items.finalMsg : '面试反馈' }} <i
                  class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="成功入职">成功入职</el-dropdown-item>
                <el-dropdown-item command="暂未入职">暂未入职</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <span class="name">面试职位：</span>
            <span class="text" v-if="items.jobObject">{{ items.jobObject.jobName }}</span>
          </li>
          <li>
            <span class="name">面试时间：</span>
            <span class="text">{{ invativeDetail.interviewTime }}</span>
          </li>
          <li>
            <span class="name">面试形式：</span>
            <span class="text">{{ invativeDetail.isSp == 0 ? '现场面试' : '远程面试' }}</span>
          </li>
          <li>
            <span class="name">面试地址：</span>
            <span class="text">{{
                invativeDetail.provinceid + invativeDetail.cityid + (invativeDetail.threeCityid ? invativeDetail.threeCityid : '') + invativeDetail.address
              }}</span>
          </li>
          <li>
            <span class="name">联系人：</span>
            <span class="text">{{ invativeDetail.linkMan }}</span>
          </li>
          <li>
            <span class="name">联系方式：</span>
            <span class="text">{{ invativeDetail.linkTel }}</span>
          </li>
          <li>
            <span class="name">面试备注：</span>
            <div class="text" v-html="invativeDetail.applyMessage"></div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!--面试反馈 -->

  </div>
</template>

<script>
import Editor from "../public/editor";
import Scascader from "../dialog/scascader";
import Zhucescascader from "../dialog/zhucescascader";
import Invative from "../enterprise/Invative";

export default {
  components: {Editor, Scascader, Zhucescascader, Invative},
  props: {
    item: Object,
    im: "",
    status: '',
    timeType: ''
  },
  inject: ["reload"],
  filters: {
    getStatus(val) {
      let status = '';
      switch (val) {
        case "4":
          status = "邀面试";
          break;
        case "5":
          status = "已接受";
          break;
        case "6":
          status = "已拒绝";
          break;
        case "8":
          status = "已结束";
          break;
        case "9":
          status = "已结束";
          break;
      }
      return status
    },
  },
  data() {
    return {
      jobInfo: {},
      loading: false,
      iscollect: false,
      items: this.item,
      underline: false,
      invativeId: "",
      dialogFormVisible: false,
      memberIds: [],
      ims: this.im,
      city: "",
      isHide: false,
      isShow: false,
      isShows: {
        show: false,
        show1: false
      },
      invativeDetail: [],
      time: "2",
      form: {
        jobApplyId: "",
        linkMan: "",
        linkTel: "",
        reservationTime: "",
      },
      error: {
        jobApplyDetailInDTO: {
          linkMan: [
            {
              required: true,
              message: "请填写企业联系人姓名",
              trigger: "change",
            },
          ],
          linkTel: [
            {
              required: true,
              message: "请填写联系方式，很重要哦！",
              trigger: "change,blur",
            },
            {
              pattern: /^[0-9]*$/,
              message: "电话号码格式不对",
              trigger: "blur",
            },
          ],
          reservationTime: [
            {
              required: true,
              message: "请选择预约面试时间",
              trigger: "change,blur",
            },
          ],
        },
      },
      disabled: false,
      upload: {
        id: '',
        jobId: '',
        memberIds: [],
        finalMsg: '',
        applyStatus: '8',
      },
    };
  },
  methods: {
    collect(memberId, collectionType) {
      if (collectionType == 1) {
        this.items.collectionType = 0;
      } else {
        this.items.collectionType = 1;
      }
      this.docollect(memberId);
    },
    goRouter(url, memberId, userType, otherId) {
      this.memberIds.push(memberId);
      let params = {
        jobId: this.items.jobObject ? this.items.jobObject.id : '',
        memberIds: this.memberIds,
        id: this.items.id,
        applyStatus: '2',
      };
      if (this.status == 1) {
        this.$api.applyFacing(params);
      }
      this.$api.addCompanyLookResumeApi({diabledId: memberId});
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
          otherId: otherId,
        },
      });
      window.open(href, "_blank");
    },
    // 邀面试
    invatie(memberId, jobInfo, id) {
      if (localStorage.getItem("time")) {
        this.time = localStorage.getItem("time");
        let b = localStorage.getItem("id");
        if (this.time && this.items.memberId == b) {
          this.$message.warning("您已邀请过该残疾人");
          return;
        }
      }
      this.memberId = memberId;
      this.$store.commit("SET_INVATIVE_ID", this.memberId);
      this.jobInfo = {
        ...jobInfo,
        iddd: id,
        memberId: this.memberId
      };
      this.jobInfo.iddd = id;
      this.dialogFormVisible = true;
    },
    guanbi() {
      this.$message.warning("您已经邀请过该残疾人");
      this.dialogFormVisible = false;
    },

    //打开预约面试
    openSubscribe(id) {
      this.$axios
          .get("/api/app-jycy-henan/henan/video/reservation/tel", {id: id})
          .then((res) => {
            if (res.data.success) {
              this.isHide = true;
              let data = res.data.data;
              this.form.jobApplyId = id;
              this.form.linkMan = data.linkMan;
              this.form.linkTel = data.linkTel;
            }
          });
    },
    // 预约视频面试时间
    reservation(form) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = await this.$axios.post(
              "/api/app-jycy-henan/henan/video/reservation",
              this.form
          );
          this.disabled = true;
          if (res.data.success) {
            this.$message({
              message: "邀请发送成功！",
              type: "success",
            });
            this.isHide = false;
            this.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.disabled = false;
          }
        }
      });
    },
    //开始视频
    openVideo(id) {
      this.isShow = true;
      this.$refs.video.init(id);
    },
    // 收藏
    async docollect(memberId) {
      let params = {
        diabledId: memberId,
        collectionType: this.collectionType,
      };
      let res = await this.$api.collectResume(params);

      let msg = "已成功收藏";
      if (this.items.collectionType == 0) {
        msg = "已取消收藏";
      }
      if (res.data.success) {
        this.$notify({
          showClose: true,
          message: msg,
          type: "success",
        });
        this.$parent.$parent.$parent.getCollect();
      }
    },
    // 有意向
    async intention(memberIds) {
      this.memberIds.push(memberIds);
      let params = {
        jobId: this.items.jobObject.id,
        id: this.items.id,
        memberIds: this.memberIds,
        applyStatus: "3",
      };
      let res = await this.$api.applyFacing(params);
      if (res.data.success) {
        this.$notify({
          showClose: true,
          message: "当前简历已转入有意向！",
          type: "success",
        });
        this.memberIds = [];
      } else {
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
      }
      console.log(this.$parent)
      this.$parent.getReceive();
      this.$parent.getcount();
    },
    // 不合适
    async noFit(memberIds) {
      this.memberIds.push(memberIds);
      let params = {
        jobId: this.items.jobObject.id,
        memberIds: this.memberIds,
        id: this.items.id,
        applyStatus: "7",
      };
      let res = await this.$api.applyFacing(params);
      if (res.data.success) {
        this.$notify({
          showClose: true,
          message: "当前简历已标记为不合适！",
          type: "success",
        });
        this.memberIds = [];
      } else {
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
      }
      this.$parent.getReceive();
      this.$parent.getcount();
    },
    //打开面试信息
    openJob(type, item) {
      if (type == 1) {
        this.isShows.show = true;
        this.invativeDetail = item.interViewInfo;
      }
    },
    //面试反馈结果
    handleCommand(command) {
      this.memberIds.push(this.items.memberId);
      this.items.finalMsg = command;
      let params = {
        jobId: this.items.jobObject.id,
        id: this.items.id,
        memberIds: this.memberIds,
        applyStatus: "8",
        finalMsg:command,
      };
      new Promise((resolve,reject) =>{
        if (this.items.applyStatus == '9'){
          reject('企业不可修改状态面试反馈状态！');
        }else{
          resolve(this.$api.applyFacing(params));
          this.memberIds = [];
        }
      })
    },
    // 关闭
    close() {
      this.dialogFormVisible = false;
      this.isShows.show = false;
      this.isShows.show1 = false;
    },
  },
  mounted() {
  },
  created() {
    this.city = localStorage.getItem("locationSite");
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/public";

.bg {
  top: -80px;
}

.far {
  background: #ff7029;
  color: #ffffff;
  padding: 0 3px;
}

.colorFF5 {
  color: #ff5500;
}

/deep/ .el-form-item__content {
  margin-left: 0px;
}

.autos {
  display: flex;
  margin-left: 0px;
  justify-content: center;

  .el-button {
    margin-left: 0px;
  }
}

/deeep/ .el-dialog__title {
  color: orange;
}

/deep/ .el-input__inner {
  height: 34px;
}

.info-person {
  position: relative;
  min-width: 860px;
  // 不合格
  .img-s {
    position: absolute;
    right: 15px;
    top: 30px;
    width: 50px;
    display: block;
  }

  height: 106px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  margin: 0 12px;
  margin-bottom: 10px;

  ul {
    flex: 1;
    box-sizing: border-box;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .out {
      float: left;
      height: 91px;
    }

    .out-flex {
      display: flex;
      // flex-direction: column;
      justify-content: left;
      flex-wrap: wrap;
      align-items: center;
    }

    li:nth-child(1) {
      flex: 2.5;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      // padding: 0 10px;
      .out-main {
        display: flex;

        .profile {
          width: 50px;
          margin-right: 20px;

          .outside {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #dfdfdf;

            img {
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 50%;
            }
          }
        }

        .info-text {
          width: 220px;
          display: flex;
          flex-direction: column;

          .tex {
            width: 40%;
            justify-content: space-between;

            h4 {
              font-size: 16px;
              font-weight: 600;
              color: #00924c;
              line-height: 22px;
              margin-bottom: 10px;
              max-width: 300px;
              // display: block;
              cursor: pointer;
            }

            .nini {
              // position: absolute;
              // top: 0px;
              // left: 150px;
              font-size: 12px;
              color: #00924c;
              margin-left: 5px;
              padding: 2px 3px;
              font-weight: normal;
            }

            span {
              color: #333333;
              font-weight: 600;
              margin-left: 10px;
            }
          }

          .out-1 {
            display: flex;
            margin-left: -7px;

            p {
              border-right: 1px solid #999999;
              height: 12px;
              padding-right: 5px;
              display: flex;
              align-items: center;
              line-height: 12px;
              margin-bottom: 10px;

              span {
                font-size: 12px;
                color: #999999;
                text-align: center;
                line-height: 17px;
                padding-left: 6px;
              }
            }

            p:last-child {
              border-right: none;
            }

            i {
              font-size: 12px;
              font-style: normal;
              display: inline-block;
              margin-left: 5px;
            }
          }
        }
      }
    }

    li:nth-child(2) {
      //flex: 2.6;
      width: 260px;
      border-left: 1px dotted #e1e1e1;
      border-right: 1px dotted #e1e1e1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-right: 20px;
      padding-left: 35px;
      align-items: center;
      box-sizing: border-box;
      // .out-flex{
      //   // min-width: 218px;
      // }
      p {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-bottom: 3px;
        margin-right: 12px;
      }

      .salary {
        color: #ff5500;
      }
    }

    li:nth-child(3) {
      position: relative;
      //flex: 4;
      width: 350px;
      justify-content: center;
      // flex-direction: column;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding-left: 15px;

      .post {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }

      .info-right {
        width: 210px;
        display: flex;
        flex-wrap: wrap;

        .look {
          margin-top: 10px;
          margin-right: 15px;

          a {
            font-size: 12px;
            font-weight: 500;
            color: #00924c;
            cursor: pointer;
            text-decoration: underline;
          }

          a:hover {
            color: #00924c;
          }
        }

        .fits {
          a {
            text-decoration: none;
          }

          .a1 {
            color: orange;
          }

          .a2 {
            color: green;
          }

          .a3 {
            color: red;
          }
        }
      }
    }
  }

  .buttons {
    margin-right: 10px;

    div {
      padding: 0 12px;
      height: 28px;
      line-height: 28px;
      font-size: 12px;
      text-align: center;
      border-radius: 4px;
      transition: all 0.2s;
    }

    div:hover {
      opacity: 0.8;
    }

    div:active {
      transform: scale(0.9);
      opacity: 0.8;
    }

    .green_plain {
      color: @00;
      background: rgba(0, 181, 86, 0.15);
    }

    .gray {
      color: #666666;
      background: rgba(180, 181, 181, 0.15);
    }

    .green {
      color: white;
      background: @00;
    }

    .red {
      color: #f62807;
      background: rgba(246, 40, 7, 0.15);
    }

    .org {
      color: coral;
      background: rgba(216, 140, 7, 0.15);
    }
  }

  .display-none {
    opacity: 0;
    width: 90px;
    height: 102px;
    background-color: #e5faef;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;

    .star {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: 22px;
      cursor: pointer;

      .iscollect {
        width: 19px;
        margin-right: 5px;

        display: inline-block;
        height: 18px;
        background: url("~static/resumes/collected.png") center center no-repeat;
        background-size: 100% 18px;
      }

      .uncollect {
        width: 19px;
        margin-right: 5px;

        display: inline-block;
        height: 18px;
        background-image: url("~static/resumes/collect.png");
        background-size: 100% 18px;
      }

      span:nth-child(2) {
        color: #00924c;
        // width: 32px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .load {
      display: flex;
      justify-content: center;
      align-items: center;

      span:nth-child(1) {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background: url("../../static/resumes/load.png") no-repeat;
        background-size: 18px 18px;
      }

      span:nth-child(2) {
        color: #00924c;
        width: 32px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.info-person:hover .display-none {
  opacity: 1;
  transition: all 0.4s;
}

.info-person:hover {
  border: 1px solid #00924c;
  border-radius: 2px;
}

//开始视频面试
.bg_video {
  text-align: center;

  .or {
    img {
      width: 124px;
      height: 124px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .tip {
    margin-top: 10px;
  }

  .footer {
    text-align: center;

    .el-button {
      width: 200px;
    }
  }
}

.el-dialog_content {
  text-align: left;
  padding: 0 30px;

  .ul {
    li {
      position: relative;
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid #E1E1E1;
      .el-dropdown{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      .el-button {
        display: flex;
        align-items: center;
        padding: 8px 10px;
      }
    }

    li:last-child {
      border-bottom: 0;
    }
  }
}
</style>
