<template>
  <div class="resumes-wrapper">
    <ul class="over cursorP">
      <li
          class="resumes"
          v-for="(item,index) in tab"
          :key="index"
          :class="{'active':active == index}"
          @click="tabs(index)"
      >{{ item }}
      </li>
    </ul>
    <div class="tabs" v-if="active==0">
      <div class="top">
        <span class="condition">应聘职位：</span>
        <FindPersonnel
            :code="'jobId'"
            size="mini"
            class="w122"
            :defaultValue="resumes.jobId"
            @setMValue="setSelectValue($event,'jobId')"
        ></FindPersonnel
        >
        <span class="condition">残疾类别：</span>
        <MulSelect
            :code="'disabled_type'"
            size="mini"
            class="w122"
            @setMValue="setSelectValue($event,'disabledType')"
            :defaultValue="resumes.disabledType"
        ></MulSelect
        >
        <span class="condition">投递时间：</span>
        <el-date-picker
            v-model="resumes.startTime"
            value-format='yyyy/MM/dd'
            size="mini"
            type="date"
            placeholder="选择日期"
            popper-class="eagle-a11y-uncut"
            @change="setFind()"
        >
        </el-date-picker>
        <span style="margin: 0 auto;">至</span>
        <!-- <el-col :span="1" >至</el-col> -->
        <el-date-picker
            v-model="resumes.endTime"
            value-format='yyyy/MM/dd'
            size="mini"
            type="date"
            placeholder="选择日期"
            popper-class="eagle-a11y-uncut"
            @change="setFind()"
        >
        </el-date-picker>
        <span class="condition" style="margin-left:10px">姓名：</span>
        <el-input
            placeholder="请输入姓名"
            v-model="resumes.disabledName"
            popper-class="elinput"
            @change="setFind()">
        </el-input>
      </div>
      <el-menu
          class="el-menu-demo tab_el_memu"
          mode="horizontal"
          @select="handleSelect"
          :unique-opened="true"
          :default-active="activeIndex"
          text-color="#333"
      >
        <el-menu-item index="1">待处理
          <el-badge class="mark" :value="count.noApplys"/>
        </el-menu-item>
        <el-menu-item index="2">已查看
          <el-badge class="mark" :value="count.looks"/>
        </el-menu-item>
        <el-menu-item index="3">有意向
          <el-badge class="mark" :value="count.intention"/>
        </el-menu-item>
        <el-submenu index="31">
          <template slot="title">面试邀请
            <el-badge class="mark" :value="count.interviews"/>
          </template>
          <el-menu-item index="31">全部</el-menu-item>
          <el-menu-item index="32">待回复</el-menu-item>
          <el-menu-item index="33">已接受</el-menu-item>
          <el-menu-item index="34">被拒绝</el-menu-item>
        </el-submenu>
        <el-menu-item index="4">不合适
          <el-badge class="mark" :value="count.noAppro"/>
        </el-menu-item>
      </el-menu>

      <div v-if="status.isHide == 0">

        <div
            class="resume loads"
            v-show="activeIndex== '1'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item" :status="resumes.applyStatus"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='2'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='3'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='31'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='32'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='33'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='34'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='36'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
        <div
            class="resume loads"
            v-show="activeIndex=='4'"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中.."
        >
          <div class="forin" v-for="item in getRecivesList" :key="item.id">
            <InfoResumes :item="item"></InfoResumes>
          </div>
        </div>
      </div>
      <div v-else>
        <status :type="status.type" :des="status.des"></status>
      </div>
    </div>
    <div class="tabs_2" v-if="active==1">
      <div class="top">
        <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
        >全选
        </el-checkbox>
        <el-button type="primary" plain @click="collectCancle" size="mini">批量取消收藏</el-button>
        <!-- <el-input placeholder="请根据残疾人姓名进行搜索收藏的简历" size="mini" class="w350"></el-input> -->
      </div>
      <el-divider></el-divider>
      <div
          class="resume loads"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中.."
          v-if="status.isHide == 0"
      >
        <el-checkbox-group v-model="checkedList" @change="handlecheckedListChange">
          <el-checkbox v-for="item in getRecivesList" :key="item.id" :label="item">
            <InfoResumes :item="item" :im="im"></InfoResumes>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-else>
        <status :type="status.type" :des="status.des"></status>
      </div>
    </div>
    <div class="tabs" v-if="active == 2">
      <div class="top">
        <ul class="ul">
          <li :class="actives == 0?'active':''" @click="resumeTab(0)">今日及以后</li>
          <li :class="actives == 1?'active':''" @click="resumeTab(1)">过去的面试</li>
        </ul>
      </div>
      <div
          class="resume loads"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中.."
      >
        <div class="forin" v-for="item in getRecivesList" :key="item.id">
          <InfoResumes :item="item" :timeType="resumes.timeType"></InfoResumes>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="work_pagination"
          :current-page.sync="resumes.pageNum"
          :page-size="resumes.pageSize"
          layout="total,prev, pager, next, jumper"
          :prev-text="pagination.pre"
          :next-text="pagination.next"
          :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import InfoResumes from "@/components/enterprise/InfoResumes";
import FindPersonnel from "components/selectComponents/MuPersonnel";

export default {
  name: "resumes",
  components: {InfoResumes, MulSelect, FindPersonnel},
  data() {
    return {
      need: 2,
      im: "0",
      active: 0,
      actives: 0,
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      status: {
        isHide: 0,
        type: "",
        des: "暂无人才数据",
      },
      loading: false,
      total: 0,
      checkedList: [],
      checkAll: false,
      isIndeterminate: true,
      activeIndex: "1",
      tab: ["我收到的简历", "我收藏的简历", "面试安排"],
      multipleSelection: [],
      resumes: {
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 8,
        applyStatus: "1",
        id: '',
        AreaTag: '',
        jobId: '',
        disabledType: '',
        disabledName: '',
        timeType: "0",
      },
      getRecivesList: [],
      collectionIds: [],
      count: {
        looks: '',
        noAppro: '',
        intention: '',
        interviews: ''
      }
    };
  },
  watch: {
    $route: {
      handler(newName, oldName) {
        this.getReceive();
      },
      deep: true,
    },
  },
  methods: {
    setFind() {
      this.getReceive();
    },
    setSelectValue(val, key) {
      this.resumes[key] = val;
      this.getReceive();
    },
    tabs(active) {
      this.getRecivesList = [];
      this.active = active;
      if (this.active == 1) {
        this.resumes.timeType = "0";
        this.getCollect();
      } else if (this.active == 0) {
        this.resumes.timeType = "0";
        this.getReceive();
      } else {
        this.resumes.applyStatus = "10";
        this.resumes.timeType = "2";
        this.actives = 0;
        this.getReceive();
      }
    },
    resumeTab(i){
      this.actives = i;
      if(i == 0){
        this.resumes.timeType = "2";
        this.getReceive();
      }else{
        this.resumes.timeType = "1";
        this.getReceive();
      }
    },
    // el-menu切换
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      if (this.active == 0) {
        if (this.activeIndex == 1) {
          this.resumes = {
            applyStatus: "1",
          };
          this.getReceive();
        } else if (this.activeIndex == 2) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "2",
          };
          this.getReceive();
        } else if (this.activeIndex == 3) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "3",
          };
          this.getReceive();
        } else if (this.activeIndex == 31) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "10",
          };
          this.getReceive();
        } else if (this.activeIndex == 32) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "4",
          };
          this.getReceive();
        } else if (this.activeIndex == 33) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "5",
          };
          this.getReceive();
        } else if (this.activeIndex == 34) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "6",
          };
          this.getReceive();
        } else if (this.activeIndex == 36) {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "10",
          };
          this.getReceive();
        } else {
          this.resumes = {
            pageNum: 1,
            pageSize: 8,
            jobId: "",
            applyStatus: "7",
          };
          this.getReceive();
        }
      }
    },
    // 我收到的请求
    async getReceive() {
      this.loading = true;
      this.resumes.id = this.$route.query.id;
      //页面跳转参数
      if (this.$route.query.idd === undefined) {
      } else {
        this.resumes.jobId = this.$route.query.idd;
      }
      let res = await this.$api.getReceiveList(this.resumes);
      if (res.data.success) {
        if (res.data.data !== null) {
          this.getRecivesList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.getRecivesList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无简历数据 ！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          alert(res.data.msg);
        }
      }
    },
    // 我收藏的简历
    async getCollect() {
      let that = this;
      this.loading = true;
      this.resumes.id = this.$route.query.id;

      let res = await that.$api.getCollectList(that.resumes);
      this.loading = false;
      if (res.data.success) {
        this.getRecivesList = res.data.data.records;
        this.total = res.data.data.total;
        if (this.getRecivesList.length <= 0) {
          this.status.isHide = 1;
          this.status.type = "post";
          this.status.des = "暂无收藏简历 ！";
        } else {
          this.status.isHide = 0;
        }
      }
    },
    // 全选
    handleCheckAllChange(val) {
      let that = this;
      that.checkedList = val ? that.getRecivesList : [];
      that.isIndeterminate = false;
    },
    handlecheckedListChange(value) {
      let that = this;
      let checkedCount = value.length;
      that.checkAll = checkedCount === that.getRecivesList.length;
      that.isIndeterminate =
          checkedCount > 0 && checkedCount < that.getRecivesList.length;
    },
    // 批量取消收藏
    collectCancle() {
      this.$confirm("是否批量删除收藏记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let collectIds = [];
            // 遍历取值
            this.checkedList.forEach((res) => {
              collectIds.push(res.id);
            });
            let collectionIds = [];
            collectionIds = collectIds;
            let that = this;
            let res = await this.$api.getRemoveCollectList({
              collectionIds: collectionIds,
            });
            if (res.data.success) {
              that.$message({
                message: res.data.message,
                center: true,
                type: "success",
              });
              this.getCollect();
            } else {
              that.$message({
                message: res.data.message,
                center: true,
                type: "error",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    // 分页
    handleCurrentChange(val) {
      // 直接调用传递的参数
      this.resumes.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.resumes.pageSize = val;
      this.resumes.pageNum = 1;
      this.tabs(this.active);
    },
    // 改变路由
    changeRouter(ids) {
      if (ids) {
        this.activeIndex = ids;
        if (this.activeIndex === 1) {
          this.resumes.applyStatus = '1';
        } else if (this.activeIndex === 2) {
          this.resumes.applyStatus = '3';
        } else if (this.activeIndex === 31) {
          this.resumes.applyStatus = '10';
        } else if (this.activeIndex === 32) {
          this.resumes.applyStatus = '4';
        } else if (this.activeIndex === 33) {
          this.resumes.applyStatus = '5';
        } else if (this.activeIndex === 34) {
          this.resumes.applyStatus = '6';
        } else {
          this.resumes.applyStatus = '7';
        }
      }
      if (!this.$route.query.active) {
        this.handleSelect(this.activeIndex);
      }
    },
    changeBouter(active) {
      if (active) {
        this.active = active;
      }
    },

    // 各种类型简历数量
    getcount() {
      this.$api.getReceiveCount().then((res) => {
        let counlist = res.data.data;
        this.count.looks = counlist.looks;
        this.count.noAppro = counlist.noAppro;
        this.count.noApplys = counlist.noApplys;
        this.count.intention = counlist.intention;
        this.count.interviews = counlist.interviews;
      })
    }

  },
  mounted() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type;
    }
    // 实现提前调用tab点击事件
    if (this.$route.query.active) {
      this.tabs(this.$route.query.active);
    } else {
      this.tabs(0);
    }
  },
  created() {
    this.changeRouter(this.$route.query.ids);
    let city = localStorage.getItem('locationSite');
    if (city === '河南省') {
      this.resumes.AreaTag = 'HeNan';
    }
    ;
    this.getcount();

  },
};
</script>

<style scoped lang="less">
@import "~assets/css/enterprise/resumes.less";

.tab_el_memu {
  .el-menu-item {
    position: relative;
  }

  .el-menu-item:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    width: 0;
    height: 3px;
    background: #00924c;
    transition: all 0.5s;
  }

  .el-menu-item:hover:after {
    width: 100%;
  }

  .el-menu-item.is-active:hover:after {
    display: none;
  }

  .el-menu-item:hover,
  .el-menu-item:focus {
    border-bottom: none !important;
  }

  .el-menu-item.is-active {
    border-bottom: 2px solid #00924c !important;
  }

  .el-menu-item.is-active:hover {
    border-bottom: 2px solid #00924c !important;
  }
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #00924c;
  color: white;
  border: 1px solid #00924c;
  padding: 5px 40px;
}

.el-menu-item {
  padding: 0px;
  margin-right: 30px;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  padding: 0px;
  margin-right: 30px;
}

.top {
  display: flex;
  margin-top: 20px;
}

.w122 {
  margin-right: 14px;
}

/deep/ .el-input {
  width: 150px;
  height: 28px;
}

/deep/ .el-input__inner {
  height: 28px;
}

.condition {
  margin-right: 10px;
}

.top{
  .ul{
    display: flex;
    width: 100%;
    border-bottom: solid 1px #e6e6e6;
    li{
      position: relative;
      padding-bottom: 20px;
      margin-right: 30px;
      cursor: pointer;
      height: 100%;
    }
    li::after{
      transition: all .5s;
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 0;
      height: 4px;
    }
    .active::after{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100%;
      height: 4px;
      background: #00924c;
    }
  }
}
</style>
